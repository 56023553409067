import React from "react"
import { useParams, Outlet, NavLink, IndexLink } from "react-router-dom"
import { Col, Nav, Row } from "react-bootstrap"
import Seo from "../helpers/Seo"

const links = [
  {
    label: "Search",
    path: "",
    icon: "bi bi-search",
    title: "Listen to responses",
  },
  {
    label: "Speak",
    path: "record",
    icon: "bi bi-mic",
    title: "Record your own response",
  },
]

const CustomLink = ({ path, label, icon, title, slug }) => (
  <Nav.Item>
    <NavLink
      to={path}
      title={title}
      as={NavLink}
      className="me-2 nav-link"
      end={path === ""}
    >
      <i className={`${icon} me-2`} />
      {label}
    </NavLink>
  </Nav.Item>
)

const Question = ({ questions }) => {
  const question = questions.find((q) => q.slug == useParams().questionSlug)

  return (
    <Row className="d-flex justify-content-center align-items-center mt-5">
      {question && <Seo title={question.text} />}
      <Col xs={12}>
        <h1 className="text-center">{question && question.text}</h1>
      </Col>
      <Col
        xs={12}
        className="d-flex flex-row justify-content-center align-items-center mb-3"
      >
        <Nav variant="pills" className="text-white">
          {links.map((props) => (
            <CustomLink
              key={`navlink-${props.label}`}
              {...props}
              slug={question && question.slug}
            />
          ))}
        </Nav>
      </Col>
      <Col xs={12}>{question && <Outlet context={[question]} />}</Col>
    </Row>
  )
}

export default Question
