import axios from "axios"

axios.defaults.headers.common["X-CSRF-Token"] = window.csrf
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

const onError = (e) => {
  console.log("API Request failed")
  console.log(e)
}

export const createClip = (clip) => {
  axios.defaults.headers.common["X-CSRF-Token"] = window.csrf
  return axios.post(window.apiPaths.clips_path, { clip })
}

export const createPlay = (clip) => {
  axios.defaults.headers.common["X-CSRF-Token"] = window.csrf
  return axios.post(`/api/clips/${clip.id}/plays`)
}

export const searchClips = ({ search_text, question }) =>
  axios
    .get(`/api/questions/${question.slug}/clips`, {
      params: {
        text: search_text,
      },
    })
    .catch(onError)

export const getQuestions = async () =>
  axios.get("/api/questions").catch(onError)

const API = {
  searchClips,
  getQuestions,
  createClip,
  createPlay,
}

export default API
