import { useState } from "react"
import { DirectUpload } from "@rails/activestorage"

export default function useUploader() {
  const [isLoading, setIsLoading] = useState(false)
  const [newClip, setNewClip] = useState(null)

  const uploadFile = (blob, callback) => {
    setIsLoading(true)
    const url = window.rails_direct_uploads_path

    let randomId = (Math.random() + 1).toString(36).substring(7)
    const file = new File([blob], `${randomId}.mp3`)
    const upload = new DirectUpload(file, url)

    const promise = new Promise((resolve, reject) => {
      upload.create((error, newBlob) => {
        if (error) {
          reject(error)
        } else {
          resolve(newBlob)
        }
      })
    })

    return promise
  }

  return {
    uploadFile,
    isLoading,
    setIsLoading,
    newClip,
    setNewClip,
  }
}
