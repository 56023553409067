import React, { useEffect } from "react"
import { Routes, Route, Link, Outlet, useParams } from "react-router-dom"
import Record from "./Record"
import { useStore } from "../helpers/store"
import API from "../helpers/apiClient"
import Home from "./Home"
import Search from "./Search"
import Layout from "./Layout"
import Question from "./Question"

const App = () => {
  const { questions, setQuestions } = useStore()

  useEffect(() => {
    API.getQuestions().then(({ data }) => setQuestions(data))
  }, [])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home questions={questions} />} />
        <Route
          path=":questionSlug"
          element={<Question questions={questions} />}
        >
          <Route index element={<Search />} />
          <Route path="record" element={<Record questions={questions} />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
