import React, { useState, useEffect, useMemo, useCallback, useRef } from "react"
import { Col, Form, ListGroup, Row, Spinner } from "react-bootstrap"
import debounce from "lodash/debounce"
import API from "../helpers/apiClient"
import { useOutletContext } from "react-router-dom"
import SearchPlayer from "./SearchPlayer"
import Seo from "../helpers/Seo"

const Search = () => {
  const [clips, setClips] = useState([])
  const [loading, setLoading] = useState(false)
  const [question] = useOutletContext()

  useEffect(() => {
    if (question) {
      search({ search_text: "" })
    }
  }, [question])

  const search = ({ search_text }) => {
    setLoading(true)
    API.searchClips({ search_text, question })
      .then(({ data }) => {
        setClips(data)
      })
      .finally(() => setLoading(false))
  }

  const handleSearch = (e) => debouncedSearch({ search_text: e.target.value })

  const debouncedSearch = useCallback(debounce(search, 900), [])

  return (
    <Row className="g-2">
      {question && <Seo title={`Search - "${question.text}"`} />}
      <Col xs={12}>
        <div style={{ position: "relative" }}>
          <Form.Control
            type="text"
            className="form-control-lg mt-4"
            placeholder="Search to get a curated playlist"
            onChange={handleSearch}
          />
          <div
            style={{
              right: 0,
              top: 0,
              paddingRight: "1rem",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              position: "absolute",
              alignSelf: "center",
            }}
          >
            {loading && <Spinner animation="border" variant="secondary" />}
          </div>
        </div>
      </Col>
      <Col xs={12}>
        {clips.length > 0 ? (
          <SearchPlayer clips={clips} />
        ) : (
          <div className="p-5">
            <h3 className="text-muted fw-light text-center">
              No results, please try again
            </h3>
          </div>
        )}
      </Col>
    </Row>
  )
}

export default Search
