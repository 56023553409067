import React from "react"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import StoreProvider from "../helpers/store"

const Root = () => (
  <BrowserRouter basename="/app">
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>
)

export default Root
