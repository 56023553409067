import PropTypes from "prop-types"
import React, { useMemo, useState, useEffect } from "react"
import moment from "moment"
import truncate from "lodash/truncate"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Button, ListGroupItem } from "react-bootstrap"
import classNames from "classnames"

const Clip = ({ clip, playClip, currentlyPlaying, isCurrentTrack, pause }) => {
  const [duration, setDuration] = useState(null)
  const { created_at, recording, transcription } = clip
  const audio = useMemo(() => new Audio(recording), [recording])

  const updateDuration = (e) => {
    setDuration(e.target.duration)
  }

  useEffect(() => {
    audio.addEventListener("durationchange", updateDuration)
    return () => {
      audio.removeEventListener("durationchange", updateDuration)
    }
  }, [audio])

  return (
    <ListGroupItem
      className="bg-dark text-light"
      style={{
        borderLeft: "2px solid white",
        borderRadius: 0,
        borderLeftColor: isCurrentTrack ? "white" : "transparent",
      }}
    >
      <Row className="d-flex align-items-center g-2">
        <Col xs={2}>
          <Button
            variant="dark"
            size="lg"
            onClick={currentlyPlaying ? pause : playClip}
          >
            <i
              className={classNames("bi", {
                "bi-play-fill": !currentlyPlaying,
                "bi-pause-fill": currentlyPlaying,
              })}
            />
          </Button>
        </Col>
        <Col className="d-flex align-items-start flex-column justify-content-center p-2">
          <i>{truncate(transcription, { separator: " ", length: 45 })}</i>
          {duration && (
            <span className="text-muted small m-0 float-right">
              {" " + moment(duration * 1000).format("s.S")} seconds,{" "}
              {clip.play_count}
            </span>
          )}
        </Col>
      </Row>
    </ListGroupItem>
  )
}

Clip.propTypes = {
  clip: PropTypes.shape({
    created_at: PropTypes.string,
    recording: PropTypes.string,
  }),
}

export default Clip
