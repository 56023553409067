import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export default function useTracking() {
  const location = useLocation()
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    const { gtag } = window
    if (!gtag) {
      console.log("ganalytics: no gtag found")
      return
    }

    // GTag in application.html.erb will handle first page load, so skip first load
    if (firstLoad) {
      setFirstLoad(false)
    } else {
      gtag("set", "page_path", location.pathname)
      gtag("event", "page_view")
    }
  }, [location])
}
