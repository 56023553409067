import consumer from "./consumer"

const TranscriptionUpdatesChannel = consumer.subscriptions.create(
  "TranscriptionUpdatesChannel",
  {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
    },
  }
)

export function ClipTranscriptionChannel(clip_id) {
  return consumer.subscriptions.create({
    channel: `TranscriptionUpdatesChannel`,
    clip_id: clip_id,
  })
}

export default TranscriptionUpdatesChannel
