import { useEffect, useRef, useState } from 'react'

export default function useTimer({ duration, onFinish }) {
  const timerRef = useRef() // remaining ms
  const lastTick = useRef() // timestamp of last tick
  const updateRef = useRef()
  const [timeRemaining, setTimeRemaining] = useState(null)

  const tick = () => {
    const newTick = Date.now()
    const newTimer = timerRef.current - (newTick - lastTick.current)
    
    if (newTimer <= 0) {
      reset()
      onFinish()
    } else {
      timerRef.current = newTimer
      lastTick.current = newTick
      setTimeRemaining((timerRef.current / 1000).toFixed(1))
    }
  }

  const timerStart = () => {
    timerRef.current = duration
    lastTick.current = Date.now()
    updateRef.current = setInterval(tick.bind(this), 50)
  }

  const reset = () => {
    clearInterval(updateRef.current)
    lastTick.current = null
    updateRef.current = null
    timerRef.current = null
    setTimeRemaining(null)
  }

  return {
    timerStart,
    timeRemaining,
    timerCancel: reset
  }
}