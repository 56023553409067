import React, { useEffect, useState } from "react"
import useRecorder from "../helpers/useRecorder"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import RecordButton from "./clip-form/RecordButton"
import RecordingUI from "./clip-form/RecordingUI"
import UploadScreen from "./clip-form/UploadScreen"
import useUploader from "../helpers/useUploader"
import API from "../helpers/apiClient"
import { useOutletContext } from "react-router-dom"
import Seo from "../helpers/Seo"

const Record = ({ questions }) => {
  const [uploading, setUploading] = useState(false)
  const [clipUploaded, setClipUploaded] = useState(false)
  const [question] = useOutletContext()
  const [error, setError] = useState(null)

  const {
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    blob,
    stream,
    recordingTime,
    resetRecorder,
  } = useRecorder()

  const { uploadFile, newClip, setNewClip } = useUploader()

  const restart = () => {
    setUploading(false)
    setClipUploaded(false)
    setNewClip(null)
    setError(null)
    resetRecorder()
  }

  const upload = async () => {
    setUploading(true)
    if (!blob) {
      console.error("No blob ready for upload")
    }

    // Upload audio to S3
    const newBlob = await uploadFile(blob)
    if (newBlob) {
      setClipUploaded(true)
    }
    // Create a clip with new S3 object in db
    API.createClip({
      recording: newBlob.signed_id,
      question_slug: question.slug,
    })
      .then(({ data }) => {
        setNewClip(data)
      })
      .catch((e) => {
        console.log(e)
        setError("Something went wrong, please try again.")
      })
  }

  return (
    <Row className="g-3 mt-4">
      {question && <Seo title={`Record - "${question.text}"`} />}
      <Col xs={12}>
        <div style={{ position: "relative" }}>
          <RecordButton
            display={!isRecording && !audioURL}
            isRecording={isRecording}
            startRecording={startRecording}
          />
          {/* Recording/Preview */}
          {!uploading && (isRecording || audioURL) && (
            <RecordingUI
              isRecording={isRecording}
              stream={stream}
              stopRecording={stopRecording}
              reset={resetRecorder}
              audioURL={audioURL}
              upload={upload}
              recordingTime={recordingTime}
            />
          )}
          {uploading && (
            <UploadScreen
              restart={restart}
              newClip={newClip}
              error={error}
              clipUploaded={clipUploaded}
              setNewClip={setNewClip}
              responsePath={`/${question.slug}`}
            />
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Record
