import { useEffect, useState, useRef } from "react"

import MicRecorder from "mic-recorder-to-mp3"

const MAX_CLIP_LENGTH = 15000
const TIMER_TICK = 10 // ms

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("")
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [blob, setBlob] = useState(null)
  const recordedChunks = useRef([])
  const [recordingTime, setRecordingTime] = useState(0)
  const [stream, setStream] = useState(null)

  const resetRecorder = () => {
    setAudioURL("")
    setIsRecording(false)
    setStream(null)
    setRecorder(null)
    setBlob(null)
    setRecordingTime(0)
    recordedChunks.current = []
  }

  useEffect(() => {
    let timer
    if (isRecording && recorder) {
      const startTime = new Date()
      setRecordingTime(0)

      const timer = setInterval(() => {
        const newTime = new Date() - startTime
        setRecordingTime(newTime)
        if (newTime >= MAX_CLIP_LENGTH - TIMER_TICK) {
          stopRecording()
        }
      }, TIMER_TICK)

      return () => {
        clearInterval(timer)
      }
    } else {
      clearInterval(timer)
    }
  }, [isRecording])

  const startRecording = () => {
    const rec = requestRecorder()
    rec
      .start()
      .then((microphoneStream) => {
        setStream(microphoneStream)
        setIsRecording(true)
      })
      .catch((e) => {
        console.log(e)
      })
    setRecorder(rec)
  }

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, newBlob]) => {
        const file = new File(buffer, "dingdong.mp3", {
          type: newBlob.type,
          lastModified: Date.now(),
        })
        const url = URL.createObjectURL(file)
        setBlob(newBlob)
        setAudioURL(url)
      })
    setIsRecording(false)
  }

  const requestRecorder = () => {
    return new MicRecorder({ bitRate: 128 })
  }

  return {
    audioURL,
    isRecording,
    stream,
    startRecording,
    stopRecording,
    resetRecorder,
    blob,
    recordingTime,
  }
}

export default useRecorder
