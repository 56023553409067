import React from "react"
import Helmet from "react-helmet"

export default function Seo({ title }) {
  return (
    <Helmet>
      <title>{`${title} | The Delay`}</title>
    </Helmet>
  )
}
