import React from "react"
import Nav from "react-bootstrap/Nav"
import { NavLink, Link } from "react-router-dom"
import DelayLogo from "images/delay07.png"
import { Container, Navbar } from "react-bootstrap"

const links = [
  {
    label: "Search",
    path: "search",
    icon: "bi bi-search",
    title: "Listen to responses",
  },
  {
    label: "Speak",
    path: "record",
    icon: "bi bi-mic",
    title: "Record your own response",
  },
]

const CustomLink = ({ path, label, icon, title }) => (
  <Nav.Item>
    <Nav.Link
      to={path}
      label={label}
      as={NavLink}
      title={title}
      className="me-2"
    >
      <i className={`${icon} me-2`} />
      {label}
    </Nav.Link>
  </Nav.Item>
)

const Header = () => {
  return (
    <Navbar bg="black" variant="dark">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img src={DelayLogo} height="40" />
        </Navbar.Brand>
        <Nav>
          <Nav.Item>
            <Nav.Link as={Link} to="/">
              Change topic
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default Header
