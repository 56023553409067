import React, { useEffect, useState, useRef } from "react"
import AudioPlayer from "../helpers/audio"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import Clip from "./Clip"
import classNames from "classnames"
import Spinner from "react-bootstrap/Spinner"
import API from "../helpers/apiClient"
import useTimer from "../helpers/useTimer"

const TRACK_DELAY = 3000 // 3 Seconds

export default function SearchPlayer({ clips }) {
  const AudioContext = window.AudioContext || window.webkitAudioContext
  const [trackIndex, setTrackIndex] = useState(0)
  const [ready, setReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioPlayer = useRef()
  const audioContextRef = useRef()
  const [currentTime, setCurrentTime] = useState(0)
  const [currentDuration, setCurrentDuration] = useState(0)
  const [reset, setReset] = useState(false)
  const [showPlaylist, setShowPlaylist] = useState(false)
  const { timerStart, timeRemaining, timerCancel } = useTimer({
    duration: TRACK_DELAY,
    onFinish: () => play()
  })

  const triggerReset = () => {
    audioPlayer.current.pause()
    setShowPlaylist(false)
    setTrackIndex(0)
    setReset(true)
  }

  useEffect(() => {
    if (audioPlayer.current) {
      triggerReset()
    }
  }, [clips])

  useEffect(() => {
    if (!audioContextRef.current) {
      audioContextRef.current = new AudioContext({ sampleRate: 44100 })
    }
    const wasPlaying = isPlaying

    setReset(false)
    setReady(false)
    setIsPlaying(false)
    setCurrentTime(0)

    if (audioPlayer.current) {
      audioPlayer.current.destroy()
    }
    audioPlayer.current = new AudioPlayer({
      track: clips[trackIndex].recording,
      audioContext: audioContextRef.current,
      playOnLoad: wasPlaying,
      onLoading: () => setReady(false),
      onReady: ({ duration }) => {
        setReady(true)
        duration && setCurrentDuration(duration)
      },
      onPlay: () => {
        API.createPlay(clips[trackIndex]).catch((e) => console.error(e))
        setIsPlaying(true)
      },
      onPause: () => setIsPlaying(false),
      onProgress: (time) => setCurrentTime(time),
      onFinish: nextTrack,
    })

    return () => {
      audioPlayer.current && audioPlayer.current.destroy()
    }
  }, [trackIndex, reset])

  useEffect(() => {
    if (isPlaying && currentTime >= currentDuration) {
      setIsPlaying(false)
      nextTrack()
      timerStart()
    }
  }, [currentTime, currentDuration, isPlaying])

  // Control Functions
  const nextTrack = () => {
    if (clips[trackIndex + 1]) {
      setTrackIndex(trackIndex + 1)
    } else {
      triggerReset()
    }
  }

  const changeTrack = (index) => {
    timerCancel()
    setIsPlaying(true)
    setTrackIndex(index)
  }

  const play = () => {
    if (!showPlaylist) setShowPlaylist(true)
    audioPlayer.current.play()
  }

  const pause = () => audioPlayer.current.pause()

  return (
    <Row>
      <Col xs={12} className="mt-3 mb-3">
        <Row>
          <Col
            xs={3}
            className="justify-content-center d-flex align-items-center flex-column"
            style={{ minHeight: "200px" }}
          >
            {ready ? (
              <Button
                className="recording-button bg-black"
                onClick={isPlaying ? pause : play}
                title="Play all"
                style={{
                  width: "15vw",
                  height: "15vw",
                  maxWidth: "150px",
                  maxHeight: "150px",
                }}
              >
                {timeRemaining && timeRemaining > 0 ? (
                  <>
                    <span className="d-none d-md-inline">Playing in {parseInt(Math.ceil(timeRemaining))}s</span>
                    <span className="d-md-none">{parseInt(Math.ceil(timeRemaining))}</span>
                  </>
                ): (<i
                  style={{ fontSize: "min(8vw, 4rem)" }}
                  className={classNames("bi", {
                    "bi-play-fill": !isPlaying,
                    "bi-pause-fill": isPlaying,
                  })}
                />)}
              </Button>
            ) : (
              <Spinner
                style={{ width: "8vw", height: "8vw" }}
                animation="border"
                variant="light"
              />
            )}
          </Col>
          <Col
            xs={9}
            className="justify-content-center d-flex flex-column align-items-start"
          >
            {clips && clips[trackIndex] && (
              <div className={classNames("fader", { "fade-in": ready })}>
                <p className="small text-muted mb-1">
                  {" " + clips[trackIndex].created_at}
                </p>
                <p
                  className="fst-italic fw-light text-warning mb-1"
                  style={{ letterSpacing: 1.8, fontSize: "1.5rem" }}
                >
                  "{clips[trackIndex].transcription}"
                </p>
                <span className="text-muted small">
                  {`Clip ${trackIndex + 1} of ${clips.length}`}
                </span>
              </div>
            )}
          </Col>
          <Col xs={12}>
            <div className="w-100 text-align-right"></div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} className="p-3 h-100" style={{ overflow: "hidden" }}>
        <ListGroup
          className={classNames("p-0 fader rounded", {
            "fade-in": clips.length > 0,
          })}
        >
          {clips.map((c, i) => (
            <Clip
              clip={c}
              key={`clip-${c.id}`}
              playClip={() => changeTrack(i)}
              isCurrentTrack={i === trackIndex}
              currentlyPlaying={isPlaying && trackIndex === i}
              pause={pause}
            />
          ))}
        </ListGroup>
      </Col>
    </Row>
  )
}
