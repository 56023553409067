import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import DelayLogo from "images/delay07.png"
import Badge from "react-bootstrap/Badge"
import Seo from "../helpers/Seo"

export default function Home({ questions }) {
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (questions.length > 0) {
      setSelectedQuestion(questions[index])
    }
  }, [selectedQuestion, questions, index])

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  if (selectedQuestion === null) return null

  return (
    <>
      <Seo title="Home" />
      <div className="w-100 justify-content-center align-items-center d-flex p-5">
        <img src={DelayLogo} height="100" />
      </div>
      <div className="mb-3">
        <Row className="g-2">
          <Col xs={12}>
            <div>
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                interval={null}
                indicators={false}
                touch={true}
                className="px-5"
              >
                {questions.map((q, i) => (
                  <Carousel.Item key={`carousel-${q.slug}`}>
                    <div
                      className="d-flex justify-content-center flex-column align-items-center p-0 px-lg-5"
                      style={{ minHeight: "35vh" }}
                    >
                      <h1 className="display-5 text-center position-relative">
                        {i === 0 && (
                          <Badge
                            bg="light"
                            className="text-black todays-topic-badge"
                          >
                            Today's topic
                          </Badge>
                        )}
                        {q.text}
                      </h1>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={6}
            className="d-flex justify-content-center justify-content-sm-end align-items-center mb-3"
          >
            <Link
              className="btn btn-light btn-lg"
              style={{ width: "235px" }}
              to={`/${selectedQuestion.slug}/record`}
            >
              <i className="bi bi-mic-fill me-2" />
              Add your response
            </Link>
          </Col>
          <Col
            xs={12}
            sm={6}
            className="d-flex justify-content-center justify-content-sm-start align-items-center mb-3"
          >
            <Link
              className="btn btn-dark btn-lg"
              style={{ width: "235px" }}
              to={`/${selectedQuestion.slug}`}
            >
              <i className="bi bi-search me-2" />
              Explore responses
            </Link>
          </Col>
        </Row>
      </div>
    </>
  )
}
