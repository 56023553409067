import React from "react"
import ReactDOM from "react-dom"
import Root from "./components/Root"

document.addEventListener("turbolinks:load", () => {
  const rootElement = document.getElementById("delay-root")

  if (rootElement) {
    ReactDOM.render(<Root />, rootElement)
  }
})

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    ReactDOM.render(<Root />, document.getElementById("delay-root"))
  })
}
