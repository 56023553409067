import React, { useEffect, useRef } from "react"
import { Button, Col, Row } from "react-bootstrap"
import moment from "moment"
import classNames from "classnames"
import visualizeStream from "../../helpers/visualizer"

const Visualizer = ({ visualizerRef }) => (
  <div className="canvas-container">
    <canvas id="visualizer" ref={visualizerRef} />
  </div>
)

export default function RecordingUI({
  isRecording,
  recordingTime,
  stopRecording,
  audioURL,
  reset,
  stream,
  upload,
}) {
  const visualizerRef = useRef()

  useEffect(() => {
    if (visualizerRef.current && stream) {
      visualizeStream({ el: visualizerRef.current, stream: stream })
    }
  }, [visualizerRef, stream])

  return (
    <Row
      style={{
        minHeight: "300px",
        transition: "0.3s ease-in-out",
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
      }}
    >
      {isRecording && (
        <>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              className="bg-black text-danger"
              onClick={stopRecording}
              title="Stop recording"
              style={{ fontSize: "2rem" }}
            >
              <i className="bi bi-stop-fill" />
              <span className="visually-hidden">Stop recording</span>
            </Button>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Visualizer visualizerRef={visualizerRef} />
          </Col>
          <Col
            xs={2}
            className="d-flex justify-content-center align-items-center"
          >
            <span className="font-monospace small">
              {moment(recordingTime).format("ss.SS")}/15.00
            </span>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <span
              style={{ marginTop: "-5rem" }}
              className="recording-pulse text-danger"
            >
              Recording in progress..
            </span>
          </Col>
        </>
      )}
      {!isRecording && audioURL && (
        <>
          <Col xs={12}>
            <Row className="d-flex flex-nowrap" style={{ height: "200px" }}>
              <Col xs={11} className="h-100 align-items-center d-flex">
                <audio src={audioURL} controls className="w-100" />
              </Col>
              <Col className="h-100 align-items-center justify-content-end d-flex">
                <Button title="Delete clip" variant="link" onClick={reset}>
                  <i className="bi bi-trash" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-end">
                <Button className="bg-white text-black" onClick={upload}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Col>
        </>
      )}
    </Row>
  )
}
