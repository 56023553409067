import React, { createContext, useState, useMemo, useContext } from "react"
import PropTypes from "prop-types"
/**
 * Use React context for a simple implementation of a store
 * for top-down state management
 */

const StoreContext = createContext()

export default function StoreProvider({ children }) {
  const [questions, setQuestions] = useState([])
  const [clips, setClips] = useState([])
  const [newClip, setNewClip] = useState(null)

  const value = useMemo(
    () => ({
      questions,
      setQuestions,
      clips,
      setClips,
    }),
    [questions, clips]
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

StoreProvider.propTypes = {
  children: PropTypes.any,
}

export function useStore() {
  const value = useContext(StoreContext)

  if (!value) {
    throw new Error("useStore must be called from within StoreProvider")
  }

  return value
}
