import React, { useMemo } from "react"
import { Outlet, useLocation, useHistory } from "react-router-dom"
import Header from "./Header"
import Container from "react-bootstrap/Container"
import HelpModal from "./HelpModal"
import useTracking from "../helpers/useTracking"

const Layout = () => {
  const location = useLocation()
  const showHeader = useMemo(() => location.pathname !== "/", [location])
  
  useTracking()

  return (
    <>
      <div
        className="p-2"
        style={{
          maxWidth: "700px",
          height: "100vh",
          margin: "0 auto",
          position: "relative",
          scrollBarWidth: "none",
        }}
      >
        {showHeader && <Header />}
        <Container>
          <Outlet />
        </Container>
      </div>
      <HelpModal />
    </>
  )
}

export default Layout
