import classNames from "classnames"
import React, { useEffect } from "react"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { ClipTranscriptionChannel } from "../../../../channels/transcription_updates_channel"
import { Alert } from "react-bootstrap"
import { Link } from "react-router-dom"

const UploadStep = ({ label, inProgress, complete }) => (
  <div
    className="d-flex flex-row align-items-center"
    style={{ minHeight: "20px", fontSize: "1.3rem" }}
  >
    <span className={classNames({ "text-muted": !complete && !inProgress })}>
      {label}
    </span>
    {inProgress && !complete && (
      <Spinner animation="border" size="sm" className="ms-3" />
    )}
    {complete && (
      <span className="text-success fs-5 ms-3">
        <i className="bi bi-check-circle" />
      </span>
    )}
  </div>
)

export default function UploadScreen({
  newClip,
  clipUploaded,
  restart,
  setNewClip,
  error,
  responsePath,
}) {
  useEffect(() => {
    if (newClip) {
      const clipChannel = ClipTranscriptionChannel(newClip.id)
      clipChannel.received = ({ clip }) => {
        setNewClip(clip)
      }
    }
  }, [newClip])
  return (
    <Row
      style={{
        minHeight: "300px",
        transition: "0.3s ease-in-out",
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
      }}
    >
      <Col xs={12}>
        {error ? (
          <>
            <Alert variant="danger">{error}</Alert>
            <Button variant="link" onClick={restart}>
              Try again
            </Button>
          </>
        ) : (
          <>
            <UploadStep
              label="Uploading clip"
              inProgress={true}
              complete={clipUploaded}
            />
            <UploadStep
              label="Processing audio"
              inProgress={clipUploaded}
              complete={newClip && newClip.transcription}
            />
            <UploadStep
              label="Complete!"
              inProgress={false}
              complete={newClip && newClip.transcription}
            />
            {newClip && newClip.transcription && (
              <div className="mt-3">
                <Card body className="mb-3 bg-dark text-white">
                  <h4>{newClip.transcription}</h4>
                </Card>
                <audio
                  className="mb-3 w-100"
                  controls
                  src={newClip.recording}
                />
                <div className="d-flex justify-content-around align-items-center flex-row">
                  <Button variant="link" onClick={restart}>
                    Add another
                  </Button>
                  <Button variant="link" as={Link} to={responsePath}>
                    View responses
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  )
}
