import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

export default function HelpModal() {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button
        className="bg-white text-black"
        title="About"
        onClick={() => setShow(true)}
        style={{
          border: "1px solid white",
          position: "fixed",
          bottom: "3vh",
          right: "3vh",
          width: "60px",
          height: "60px",
          borderRadius: "100%",
        }}
      >
        <i className="bi bi-question-lg fs-3" />
      </Button>
      <Modal
        show={show}
        centered
        onHide={() => setShow(false)}
        className="text-black"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title className="p-3">About Delay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="p-3">
            Delay is a voice chat platform that removes the noise and clutter of
            traditional social media platforms. By delaying user's audio
            responses to create a curated audio feed of topics of interest.
            Share your 15 seconds and truly get heard.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
