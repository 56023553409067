import React from "react"
import { Col, Form, Row } from "react-bootstrap"
import moment from "moment"
import classNames from "classnames"

export default function RecordButton({ isRecording, startRecording, display }) {
  return (
    <button
      aria-label="Record button"
      className={classNames(`recording-button`, {
        "recording-active": isRecording,
        "d-none": !display,
      })}
      onClick={startRecording}
      title={"Click to record"}
    >
      <div>
        <i className="bi bi-mic-fill" />
      </div>
      <span className="visually-hidden">Record</span>
    </button>
  )
}
